import { Save,ObtenerObject, Obtener,Editar } from "@/Generico/Conexion";
import { axiosKtb } from "@/plugins/axios";
import TurnoAperturaResponse from "./AperturaTurno";
import TurnoAperturaRequest from "./AperturaTurnoRequest";



export async function CrearApertura(rquest:TurnoAperturaRequest)
{
    return Save<TurnoAperturaRequest>('aperturaturno/AperturarTurno/',rquest,axiosKtb);
}

export async function CierreTurno(rquest:TurnoAperturaRequest)
{
    return Editar<TurnoAperturaRequest>('aperturaturno/CierreTurno/',rquest,axiosKtb);
}
export async function ValidarApertura(plantaId:number,estado:number)
{
    return ObtenerObject<TurnoAperturaResponse>(`aperturaturno/validarapertura/${plantaId}/${estado}`,axiosKtb);
}

export async function ObtenerApertura(plantaId:number,estado:number)
{
    return Obtener<TurnoAperturaResponse>(`aperturaturno/obtenerApertura/${plantaId}/${estado}`,axiosKtb);
}