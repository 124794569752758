export class Pallet 
{
    largoId!:number;
    largoName!:string;
    espesorId!:number;
    espesorName!:string;
    volumen!:number;
    palletId!:string;
    palletIdVisual!:string;
    pie:number=0;
    pulgada:number=0;
    accion!:string;
    numeroPlantilla!:number;

    constructor(_palletId:string,_largoId:number,_largoName:string,_espesorId:number,_espesorName:string,_volumen:number,_pie:number,_pulgada:number,
        _palletIdVisual:string)
    {
        this.palletIdVisual = _palletIdVisual;
        this.palletId = _palletId;
        this.largoId = _largoId;
        this.largoName = _largoName;
        this.espesorId = _espesorId;
        this.espesorName = _espesorName;
        this.volumen = _volumen;
        this.pie = _pie;
        this.pulgada = _pulgada;
    }
}
export class PalletActualizarRequest 
{
    palletId!: number;
    despachoId!: number;
    usuarioId!: string;
    largoId!: number;
    espesorId!: number;
    ancho!: number;
    motivo!: string;
    accion!: string;
    numPlantilla!: number;
    volumen!: number;
}



export class RecepcionGenerica
{
    id!:number;
    volumen!:number;
    items:Array<Pallet> =[];

    constructor(_id:number,_volumen:number,_items:Array<Pallet>)
    {
        this.id = _id;
        this.volumen = _volumen;
        this.items = _items;
    }
}