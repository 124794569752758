




































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import {
  RecepcionKtb,
  ObtenerRegistro,
  ObtenerRecepcionDetallePallet,
} from "@/entidades/KTB/RegistroInformacion/RecepcionListadoQuery";
import {
  RecepcionTerceroRequest,
  ObtenerHeader,
  CerrarRecepcionRequest,
  CerrarRecepcion,
} from "@/entidades/KTB/RegistroInformacion/RecepcionTercero";
import {
  ConfirmarTablonModal,
  ConfirmarTablonModalDetalle,
  GuardarTablon,
  SubirImagen,
  SubirImagenRequest,
  ObtenerImagenKtb,
} from "@/entidades/KTB/RegistroInformacion/ConfirmarTablon";
import {
  ValidarApertura,
  TurnoAperturaResponse,
} from "@/entidades/KTB/Turno/Index";
import {
  ObtenerPalletRecepcionProduccion,
  ProduccionListado,
} from "@/entidades/KTB/Produccion/ProduccionQuery";
import { Pallet, RecepcionGenerica } from "@/entidades/Calificacion/pallet";
import { Action, State } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import { Planta } from "@/entidades/Maestro/Planta";
import { Largo } from "@/entidades/Maestro/Ktb/Largo";
import { Espesor } from "@/entidades/Maestro/Ktb/Espesor";
import { Defecto } from "@/entidades/Maestro/Ktb/Defecto";
import { TurnoQuery } from "@/entidades/KTB/RegistroInformacion/Turno";
import Alerta from "@/entidades/Sistema/Alerta";
import { Calificacion } from "@/entidades/MaderaVerde/KtbDespacho";
import { ObtenerRecepcionDetalle } from "@/entidades/KTB/Reporte/ConexionDbReporte";
@Component({
  name: "RecepcionMaderaView",
  components: {
    tabla: () => import("@/components/General/TablaGeneralFinal.vue"),
    transferir: () => import("@/components/KTB/Operacion/Transferir.vue"),
    confirmarVolumen: () =>
      import("@/components/KTB/Operacion/ConfirmarVolumenTrozas.vue"),
    confirmarTablon: () =>
      import("@/components/KTB/Operacion/ConfirmarVolumenTablon.vue"),
    recepcionTerceroHeader: () =>
      import("@/components/KTB/Operacion/Recepcion/RecepcionTercero.vue"),
    recepcionTerceroDetalle: () =>
      import(
        "@/components/KTB/Operacion/Recepcion/RecepcionTerceroDetalle.vue"
      ),
    produccionAdd: () =>
      import("@/components/KTB/Operacion/Produccion/ProduccionManual.vue"),
    calificar: () => import("@/components/KTB/Operacion/Calificar.vue"),
    tomarFoto: () => import("@/components/KTB/TomarFoto.vue"),
    cardImagen: () => import("@/components/General/VisualizarImagen.vue"),
  },
})
export default class RecepcionMaderaView extends Vue {
  //VUEX
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  @State("plantaAsignada", { namespace: "authModule" }) plantas!: Array<Planta>;
  @State("espesor", { namespace: "maestroKtb" }) listadoEspesor!: Array<
    Espesor
  >;
  @State("defecto", { namespace: "maestroKtb" }) listadoDefecto!: Array<
    Defecto
  >;
  @State("largo", { namespace: "maestroKtb" }) listadoLargo!: Array<Largo>;
  @State("turno", { namespace: "maestroKtb" }) listadoTurno!: Array<TurnoQuery>;
  @State("usuarioId", { namespace: "authModule" }) usuarioId!: string;
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Fecha", sortable: true, value: "fechaDespacho" },
    { text: "Hacienda", sortable: true, value: "haciendaName" },
    { text: "Guia Remisión", sortable: true, value: "guiaRemision" },
    { text: "# Despacho", sortable: true, value: "numDespacho" },
    { text: "Cod. Unico", sortable: true, value: "codigoUnico" },
    { text: "Placa", sortable: false, value: "placa" },
    { text: "Formato", sortable: true, value: "formatoName" },
    { text: "Planta", sortable: true, value: "plantaName" },
    { text: "Volumen", sortable: false, value: "volumen" },
    { text: "Accion", sortable: false, value: "actions" },
  ];
  headerManual: { text: string; sortable: boolean; value: string }[] = [
    { text: "Fecha", sortable: false, value: "fecha" },
    { text: "Proveedor", sortable: false, value: "proveedorName" },
    { text: "Guia Forestal", sortable: false, value: "guiaForestal" },
    { text: "PO", sortable: false, value: "po" },
    { text: "Placa", sortable: false, value: "placaName" },
    { text: "Volumen", sortable: false, value: "volumen" },
    { text: "Accion", sortable: false, value: "actions" },
  ];

  //propiedas
  recepcionGenerica?: RecepcionGenerica;
  aperturaValidada: boolean = false;
  turnoAsignado?: TurnoAperturaResponse;
  modalRecepcionManual: boolean = false;
  tab: number = 0;
  plantaId: number = 1;
  tipoProceso: string = "recepcion";
  plantaIdOld: number = 0;
  listadoRecepcion: Array<RecepcionKtb> = [];
  listadoRecepcionManual: RecepcionTerceroRequest[] = [];
  listadoProduccionDetalle: Array<ProduccionListado> = [];
  modal: boolean = false;
  componenteName: string = "";
  imagenString: string = "";
  itemRecepcion: RecepcionKtb | undefined = undefined;
  width: number = 500;
  itemRecepcionSeleccionada: RecepcionTerceroRequest | undefined = undefined;
  async obtenerRecepcion() {
    try {
      this.changeLoading(
        new Loading(true, "Cargando informacion...Espere por favor")
      );
      if (this.plantaId > 0) {
        var rsp = await ObtenerRegistro(this.plantaId);
        if (rsp.isSuccess == true) {
          this.listadoRecepcion = rsp.isResult;
        } else {
          this.listadoRecepcion = [];
          this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 2000));
        }
      }
    } catch (error) {
      //@ts-ignore
      this.changeAlerta(new Alerta(error, true, "danger", 2000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async cargarInformacionRecepcionManual() {
    try {
      this.changeLoading(
        new Loading(true, "Cargando informacion...Espere por favor")
      );
      if (this.plantaId > 0) {
        var rsp = await ObtenerHeader(this.plantaId);
        if (rsp.isSuccess == true) {
          this.listadoRecepcionManual = rsp.isResult;
        } else {
          this.listadoRecepcionManual = [];
          this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 2000));
        }
      }
    } catch (error) {
      //@ts-ignore
      this.changeAlerta(new Alerta(error, true, "danger", 2000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  abrilEnviarPlanta(item: RecepcionKtb) {
    this.plantaIdOld = this.plantaId;
    this.itemRecepcion = item;
    this.componenteName = "transferir";
    this.modal = true;
  }

  confirmacionTrozas(item: RecepcionKtb) {
    this.width = 500;
    this.plantaIdOld = this.plantaId;
    this.itemRecepcion = item;
    this.componenteName = "confirmarVolumen";
    this.modal = true;
  }

  async confirmacionTablon(item: RecepcionKtb) {
    this.changeLoading(
      new Loading(true, "Cargando informacion...Espere por favor")
    );
    try {
      var response = await ObtenerRecepcionDetallePallet(item.id);
      if (response.isSuccess) {
        item.items = response.isResult;
        this.plantaIdOld = this.plantaId;
        this.width = 1000;
        this.itemRecepcion = item;
        this.componenteName = "confirmarTablon";
        this.modal = true;
      }
    } catch (error) {
    } finally {
      this.changeLoading(
        new Loading(false, "Cargando informacion...Espere por favor")
      );
    }
  }
  abrirModalRegistrarHeader() {
    this.width = 800;
    this.componenteName = "recepcionTerceroHeader";
    this.modalRecepcionManual = true;
  }
  modalProduccionAdd(item: RecepcionTerceroRequest) {
    this.width = 800;
    this.itemRecepcionSeleccionada = item;
    this.componenteName = "produccionAdd";
    this.modalRecepcionManual = true;
  }
  tomaFotoEvent(item: RecepcionTerceroRequest) {
    this.width = 800;
    this.itemRecepcionSeleccionada = item;
    this.componenteName = "tomarFoto";
    this.modalRecepcionManual = true;
  }

  async viewDetalleRecepcion(item: RecepcionTerceroRequest) {
    this.changeLoading(
      new Loading(true, "Cargando informacion...Espere por favor")
    );
    try {
      let response = await ObtenerPalletRecepcionProduccion(item.recepcionId);
      if (response.isSuccess === true) {
        this.width = 1250;
        this.componenteName = "recepcionTerceroDetalle";
        this.listadoProduccionDetalle = response.isResult;
        this.modalRecepcionManual = true;
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  async cerrarRecepcion(item: RecepcionTerceroRequest) {
    this.changeLoading(
      new Loading(true, "Cargando informacion...Espere por favor")
    );
    try {
      let response = await ObtenerPalletRecepcionProduccion(item.recepcionId);
      if (response.isSuccess === true) {
        let pallet: Array<Pallet> = [];
        response.isResult.forEach((s) => {
          pallet.push(
            new Pallet(
              s.palletId,
              s.largoId,
              s.largoName,
              s.espesorId,
              s.espesorName,
              s.volumen,
              s.pie,
              s.pulgada,
              ""
            )
          );
        });
        this.width = 900;
        this.tipoProceso = "manual";
        this.recepcionGenerica = new RecepcionGenerica(
          item.recepcionId,
          item.volumen,
          pallet
        );
        this.componenteName = "calificar";
        this.modalRecepcionManual = true;
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  async guardarCalificacion(item: Calificacion, verificador: number) {
    this.changeLoading(
      new Loading(true, "Cargando informacion...Espere por favor")
    );
    let infoEnviar: ConfirmarTablonModal = new ConfirmarTablonModal();
    try {
      infoEnviar.calificacion = item;
      infoEnviar.id =
        this.recepcionGenerica !== undefined ? this.recepcionGenerica.id : 0;
      infoEnviar.usuarioId = this.usuarioId;
      infoEnviar.plantaId = this.plantaId;
      infoEnviar.volumen = item.volumen;
      infoEnviar.items = [];
      infoEnviar.procedencia = "TERCEROS";
      let response = await GuardarTablon(infoEnviar);
      if (response.isSuccess) {
        this.changeAlerta(
          new Alerta(response.isMessage, true, "primary", 3000)
        );
        this.limpiarManual();
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async guardarImagen(imagen: string) {
    this.changeLoading(
      new Loading(true, "Cargando informacion...Espere por favor")
    );
    try {
      let partFile = imagen.split(",");
      let infoEnviar: SubirImagenRequest = new SubirImagenRequest(
        this.itemRecepcionSeleccionada?.recepcionId!,
        partFile[1]
      );
      let response = await SubirImagen(infoEnviar);
      if (response.isSuccess) {
        this.changeAlerta(
          new Alerta(response.isMessage, true, "primary", 3000)
        );
        this.limpiarManual();
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (e) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async visualizarImagen(item: RecepcionTerceroRequest) {
    try {
      this.changeLoading(new Loading(true, "Cargando informacion"));
      var response = await ObtenerImagenKtb(item.recepcionId!);
      console.log(response);
      if (response.isSuccess) {
        this.componenteName = "cardImagen";
        this.imagenString = response.isResult[0].imagen;
        this.modalRecepcionManual = true;
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
      //@ts-ignore
      this.changeAlerta(new Alerta(error, true, "danger", 1000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  limpiar() {
    this.modal = false;
    this.componenteName = "";
    this.plantaId = this.plantaIdOld;
    this.obtenerRecepcion();
  }

  limpiarManual() {
    this.modalRecepcionManual = false;
    this.componenteName = "";
    this.cargarInformacionRecepcionManual();
  }

  get propiedades() {
    if (this.componenteName == "transferir") {
      return { item: this.itemRecepcion };
    } else if (this.componenteName == "confirmarVolumen") {
      return { item: this.itemRecepcion };
    } else if (this.componenteName == "confirmarTablon") {
      return { item: this.itemRecepcion };
    } else if (this.componenteName == "produccionAdd")
      return {
        listadoEspesor: this.listadoEspesor,
        listadoLargo: this.listadoLargo,
        plantaId: this.plantas[0].plantaId,
        aperturaTurno: this.turnoAsignado,
        procedencia: "TERCEROS",
        recepcionId:
          this.itemRecepcionSeleccionada !== undefined
            ? this.itemRecepcionSeleccionada.recepcionId
            : 0,
      };
    else if (this.componenteName == "calificar") {
      return {
        items: this.recepcionGenerica,
        listadoDefecto: this.listadoDefecto,
        proceso: "ktb",
        tipoProceso: this.tipoProceso,
      };
    } else if (this.componenteName == "recepcionTerceroDetalle") {
      return { item: this.listadoProduccionDetalle };
    } else if (this.componenteName == "tomarFoto") {
      return { id: this.itemRecepcionSeleccionada?.recepcionId };
    } else if (this.componenteName === "cardImagen") {
      return { imagen: this.imagenString };
    }
  }

  mounted() {
    //this.obtenerRecepcion();
  }
  @Watch("tab")
  tabWatch(value: number, valueOld: number) {
    if (value == 1) {
      ValidarApertura(this.plantas[0].plantaId, 1).then((c) => {
        this.aperturaValidada = c.isSuccess;
        this.turnoAsignado = c.isResult;
      });
      //this.aperturaValidada = rsp.isSuccess;
    }
  }
}
