import {axiosKtb} from '@/plugins/axios';
import {Obtener,Save} from '@/Generico/Conexion'

export class RecepcionTerceroRequest
{
    plantaId:number=0;
    po:string="";
    proveedorId:number=0;
    placaId:number=0;
    placaDescripcion:string="";
    guiaForestal:string="";
    usuarioId:string="";
    proveedorName:string="";
    placaName:string="";
    recepcionId:number=0;
    volumen:number=0;
    material:string="";
}

export class CerrarRecepcionRequest
{
    recepcionId:number=0;
    usuarioId:string="";
}

export async function GuardarHeader(item:RecepcionTerceroRequest)
{
    return Save<RecepcionTerceroRequest>('recepcion/RegistrarTercero',item,axiosKtb);
}
export async function ObtenerHeader(plantaId:number)
{
    return Obtener<RecepcionTerceroRequest>(`recepcion/ObtenerRecepcionTerceroHeader/${plantaId}`,axiosKtb);
}

export async function CerrarRecepcion(item:CerrarRecepcionRequest)
{
    return Save<CerrarRecepcionRequest>('recepcion/CerrarRecepcionTercero',item,axiosKtb);
}