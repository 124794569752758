import { Obtener3, Editar } from '@/Generico/Conexion';
import { axiosKtb } from '@/plugins/axios';
import RecepcionDetalleAgrupado from './RecepcionDetalleAgrupado';
export class RecepcionKtb {
    id: number = 0;
    numDespacho: string = "";
    codigoUnico: string = "";
    fechaDespacho: string = "";
    placa: string = "";
    formatoId: number = 0;
    formatoName: string = "";
    plantaName: string = "";
    haciendaName: string = "";
    plantaId: number = 0;
    volumen: string = "";
    volumenCalculo: number = 0;
    items: Array<RecepcionKtbDetalle> = [];
}
export class RecepcionKtbDetalle {
    factorHuecoTablon: number = 0;
    id: number = 0;
    largoName: string = "";
    espesorName: string = "";
    espesorId: number = 0;
    largoId: number = 0;
    pie: number = 0;
    pulgada: number = 0;
    ancho: number = 0;
    numPlantillas: number = 0;
    palletId: string = "";
    palletIdVisual: string = "";
    volumen: number = 0;
    fechaTumba: string = "";
    listadoDefecto: Array<DefectoEnviar> = [];
    agrupadoEspesor: RecepcionDetalleAgrupado[] = []
    agrupadoLargo: RecepcionDetalleAgrupado[] = []
}

export class TransferirModel {
    id: number = 0;
    usuarioId: string = "";
    plantaId: number = 0;

    constructor(_id: number, _usuarioId: string, _plantaId: number) {
        this.id = _id;
        this.usuarioId = _usuarioId;
        this.plantaId = _plantaId;
    }
}
export class ConfirmarTrozasModal {
    id: number = 0;
    usuarioId: string = "";
    corazon: number = 0;
    volumen: number = 0;
    plantaId: number = 0;
    constructor(_id: number, _usuarioId: string, _corazon: number, _volumen: number, _plantaId: number) {
        this.id = _id;
        this.usuarioId = _usuarioId;
        this.corazon = _corazon;
        this.volumen = _volumen;
        this.plantaId = _plantaId;
    }
}
export class DefectoEnviar {
    defectoId: number = 0;
    cantidad: number = 0;
}

export async function ObtenerRegistro(plantaId: number) {
    return Obtener3<RecepcionKtb>(`recepcion/obtenerrecepcionplanta/${plantaId}`, axiosKtb);
}

export async function TransferirPlanta(model: TransferirModel) {
    return Editar<TransferirModel>("recepcion/transferirplanta/", model, axiosKtb);
}

export async function ConfirmarTrozas(trozas: ConfirmarTrozasModal) {
    return Editar<ConfirmarTrozasModal>("recepcion/confirmarTrozas", trozas, axiosKtb);
}

export async function ObtenerRecepcionDetallePallet(recepcionId: number) {
    return Obtener3<RecepcionKtbDetalle>(`recepcion/obtenerrecepciondetalleid/${recepcionId}`, axiosKtb);
}