import { Save,ObtenerPost2, Obtener,DescargarFile, Editar } from "@/Generico/Conexion";
import { axiosKtb } from "@/plugins/axios";
import RecepcionDetaleAgrupado from '../RegistroInformacion/RecepcionDetalleAgrupado'
import PrinterPalletRequest from "./PrinterPalletRequest";
export class ProduccionListado
{
    id:number = 0;
    palletId:string ="";
    haciendaName:string ="";
    haciendaId:number = 0;
    fecha:string ="";
    turnoName:string ="";
    largoId:number =0;
    espesorId:number=0;
    largoName:string ="";
    espesorName:string ="";
    pie:number=0;
    pulgada:number=0;
    numPlantilla:string="";
    volumen:number =0;
    mesaName:string="";
    lineaName:string="";
    usuarioName:string ="";
    empleadoName:string="";
    t2K:number =0;
    status:string="";
    estadoDescripcion:string="";
    usuarioId:string ="";
    agrupadoEspesor:RecepcionDetaleAgrupado[] = [];
    agrupadoLargo:RecepcionDetaleAgrupado[] = [];
}

export class ProduccionQuery
{
    largoId?: number;
    espesorId?: number;
    turnoId?: Array<number>;
    desde?: string;
    hasta?: string;
}


export class ProduccionRequest
{
    usuarioId?: string;
    plantaId?: number;
    largoId?: number;
    espesorId?: number;
    mesaId?: number;
    lineaId?: number;
    numeroPlantilla?: number;
    anchoId?: number;
    volumen?: number;
    empleadoId?:number;
    turnoAperturaId?:number;
    procedencia?:string;
    recepcionId?:number;
    constructor(_plantaId?:number,_usuarioId?:string,_largoId?:number,_espesorId?:number,
              _mesaId?:number,_lineaId?:number,_numPlantilla?:number,_ancho?:number,_volumen?:number,
              _empleadoId?:number,_turnoAperturaId?:number,_procedencia?:string,_recepcionId?:number)
    {
        this.usuarioId = _usuarioId;
        this.plantaId = _plantaId;
        this.largoId = _largoId;
        this.espesorId = _espesorId;
        this.mesaId = _mesaId;
        this.lineaId = _lineaId;
        this.numeroPlantilla = _numPlantilla;
        this.anchoId = _ancho;
        this.volumen = _volumen;
        this.empleadoId = _empleadoId;
        this.turnoAperturaId = _turnoAperturaId;
        this.procedencia = _procedencia;
        this.recepcionId = _recepcionId;
    }
}

export async function GuardarPallet(request:ProduccionRequest)
{
    return Save<ProduccionRequest>('produccion/guardar',request,axiosKtb);
}

export async function ObtenerProduccion(query:ProduccionQuery)
{
    return ObtenerPost2<ProduccionListado,ProduccionQuery>('produccion/obtenerproduccion',query,axiosKtb);
}

export async function GenerarExcel(query:ProduccionQuery)
{
    return DescargarFile<ProduccionQuery>('produccion/generarExcel',query,axiosKtb);
}

export async function ObtenerPallet(plantaId:number,espesorId:number,largoId:number,procedenciaId:number)
{
    return Obtener<ProduccionListado>(`produccion/obtenerpallet/${plantaId}/${espesorId}/${largoId}/${procedenciaId}`,axiosKtb);
}

export async function EliminarPallet(request:ProduccionListado)
{
    return Editar<ProduccionRequest>('produccion/deletepallet',request,axiosKtb);
}

export async function ImprimirPallet(request:PrinterPalletRequest)
{
  return Save<PrinterPalletRequest>('produccion/imprimirpallet',request,axiosKtb);
}

export async function ObtenerPalletRecepcionProduccion(recepcionId:number)
{
    return Obtener<ProduccionListado>(`produccion/ObtenerPalletRecepcionProduccion/${recepcionId}`,axiosKtb);
}
